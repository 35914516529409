@import "assets/styles/variables.scss";

.seqrops-home-search-section {
  padding: 10px;

  input {
    width: 100%;
    font-size: 14px;
    background-color: white;
    border: 1px solid #eeecec !important;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    margin-right: 10px;
    :focus{
      border-color: #eeecec !important;
    }
  }

  button {
    background-color: #d3edff;
    border: 1px solid #c5e5ff;
    border: none;
    padding: 9px !important;
    color: white;
    border-radius: 4px;
    padding: 12px 14px !important;
  }
}



.seqrops-inital-page-wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
}

.seqrops-no-model-image-text-section {
  padding-top: 10px;
  color: #a6a6b0;
}

.seqrops-create-model-button-section {
  padding-top: 20px;

  .initialscreen-createBtn {
    border: 1px solid $createBtnOutline;
    padding: 15px;
    border-radius: 3px;
    background-color: white;
    color: $createBtnOutline;
    transition: all 0.5s $ease;
    &:hover {
      border-color: transparent;
      color: white;
      background-color: $createBtnOutline;
    }
  }
}
.seqrops-no-model-image-section {
  display: flex;
  justify-content: center;
  img {
    width: 280px;
  }
}
.seqrops-no-model-image-text-section {
  font-size: 15px;
}
.seqrops-no-model-image-text-section {
  display: flex;
  justify-content: center;
}

.seqrops-create-model-button-section {
  display: flex;
  justify-content: center;
}
.seqrops-pagination-wrapper .MuiTablePagination-root{
  position: relative;
  left:24vw
}