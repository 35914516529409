.seqrops-instance-head-section {
    justify-content: center;

    p {
        font-size: 22px;
        margin: 0;
        color: #5B6781;
    }

    input {
        width: 32vw;
        background-color: white;
        border: 1px solid #e9e9ed;
    }

    button {
        border: none;
        background-color: none;
        padding: 6px 15px;
    }
}

.model-name-section {
    font-weight: bolder;
}


.seqrops-instance-table table tr:nth-of-type(2n) {
    background: #f5f5f5;
}

button.search_btn {
    background-color: #d3edff;
    border: 1px solid #c5e5ff !important;
    border-radius: 4px;
    margin-left: 10px;
}

.sequrops_add_btn {
    &:focus {
        box-shadow: none !important;
        border: none !important;
    }
}