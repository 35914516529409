.seqrops-model-list-item-container {
  background-color: #363F5E;
  color: #333;
  font-weight: bold;
  border-radius: 5px;
  width: 300px;
  height: 13vh;
  position: relative;

  .seqrops-modelItem {
    //justify-content: center;
    align-items: center;
    color: #faf9f9;
    font-size: 16px;
    //padding-bottom: 20px;
    justify-content: space-between;
    padding-top: 31px;
    
  }

  .seqrops-modelDescription {
    color: #faf9f9;
    font-size: 13px;
    font-weight: 300;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    justify-content: space-between;
    overflow: hidden;
    width: 160px;
    word-wrap: break-word;

    
  }
  .seqrops-modelInstanceCount{
    color: white;
    font-size: 13px;
    font-weight: 300;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    justify-content: space-between;
    overflow: hidden;
    width: 180px;
    word-wrap: break-word;
  }
  .seqrops-modelInstanceCountLabel{
    margin-right: 55px;
    font-size: 14px;
    font-weight: 400;

  }

  .seqrops-modelIsEquipment {
    position: absolute;
    right: 0;
    right: 20px;
    top: 10px;
    z-index: 99;
    img{
      width: 22px;
    }
}


  .modelBox {
    text-decoration: none;
    cursor: pointer;
    //    &:hover{
    //     background: #d7f3ff;
    //    }
  }
  
  .modelIcon {
    background: #62ACFA;
    padding: 0px 15px 0px 15px;
    margin-left: 10px;
    height: 13vh;
    display: flex;
    align-items: center;
  
    img {
      width: 30px;
      font-size: 10px;
      color: white;
    }
  }
  .seqrops-model-options-section {
    justify-content: flex-end;
    align-items: center;
    height: 39px;

    &:hover {
      background: #c6ebff;
    }
    img {
      padding: 5px;
      width: 29px;
    }

    button {
      border: none;
      background: none;
    }
  }
}
.seqrops-view-row {
  cursor: pointer;
}

.seqrops-home-model-list-section {
  padding-bottom: 80px;
}

.seqrops-model-list-item-container {
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #273050;
    clip-path: circle(157px at 123% -46%);
    transition: .5s ease-in-out;
    border-radius: 5px;
    z-index: 0;

  }
}
.seqrops-model-list-item-container:hover:after {     clip-path: circle(200px at 122% -48%);
  opacity: 0.2; }
.z-index-9{
  z-index: 999;
}